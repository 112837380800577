import React from "react";
import HeroSection from "./../components/HeroSection";
import FeaturesSection from "./../components/FeaturesSection";
import ClientsSection from "./../components/ClientsSection";
import TeamBiosSection from "./../components/TeamBiosSection";
import { useRouter } from "../util/router";
import HeroParticles from "../components/HeroParticles";

function IndexPage(props) {
  const router = useRouter();

  return (
    <>
    <HeroParticles />

      <HeroSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Thanks for visiting"
        subtitle="Thanks for visting ADMG Solutions, lets find out what we can do for you."
        buttonText="Get Started"
        buttonColor="primary"
        buttonOnClick={() => {
          // Navigate to pricing page
          router.push("/pricing");
        }}
      />
      <FeaturesSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Skill sets and expertise."
        subtitle="Full stack development, from front to back and back again. Web Development, mobile, server, cloud and machine learning."
      />
      <ClientsSection
        bg="light"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Tech Stacks that we use"
        subtitle=""
      />
      <TeamBiosSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Meet the Team"
        subtitle=""
      />
    </>
  );
}

export default IndexPage;
