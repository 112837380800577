import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "./SectionHeader";
import FeaturesList from "./FeaturesList";

function FeaturesListSection(props) {
    return (
        <Section
            bg={props.bg}
            textColor={props.textColor}
            size={props.size}
            bgImage={props.bgImage}
            bgImageOpacity={props.bgImageOpacity}
        >
            <Container>
                <SectionHeader
                    title={props.title}
                    subtitle={props.subtitle}
                    size={2}
                    spaced={true}
                    className="text-center"
                ></SectionHeader>
                <FeaturesList
                    items={props.items}
                image="Simulator.png"
                    title="Title"
                ></FeaturesList>
            </Container>
        </Section>
    );
}

export default FeaturesListSection;
