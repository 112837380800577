import React from "react";
import HeroSection from "./../components/HeroSection";
import StatsSection from "./../components/StatsSection";
import TeamBiosSection from "./../components/TeamBiosSection";
import CtaSection from "./../components/CtaSection";
import { useRouter } from "./../util/router.js";
import FeaturesListSection from "../components/FeaturesListSection";

function AboutPage(props) {
  const router = useRouter();

  return (
    <>
      <HeroSection
        bg="white"
        textColor="dark"
        size="lg"
        bgImage=""
        bgImageOpacity={1}
        title="We solve your problems"
        subtitle="ADMG Solutions has 15 years of programming experience and building great applications and solutions. Recently we've built app's in native Swift for the iOS platform, a python application written in Django "
      />
      <FeaturesListSection
          bg="white"
          textColor="dark"
          size="md"
          bgImage=""
          bgImageOpacity={1}
          title="Previous Projects and Contracts"
          subtitle="Here is a mix of the Products and Contracts that ADMG Solutions has worked on"
      items={[
          {
              ListItem: "Built with Swift using AutoLayout "
          },
          {
              ListItem: "Uses Rest Services to interact with the platform version of Casenotes"
          },
          {
              ListItem: "Authenticates with server using bearer token"
          },
          {
              ListItem: "Can offline notes for later syncing with server if data is not available"
          },
          {
              ListItem: "Allows for reporting of notes by client/staff/fee level"
          },
          {
              ListItem: "Allows for storing images taken with the iOS camera and sending image to server"
          },
          {
              ListItem: "Built-in Web viewer if sent data attached from platform side"
          }
      ]}>
      </FeaturesListSection>
        <FeaturesListSection
            bg="white"
            textColor="dark"
            size="md"
            bgImage=""
            bgImageOpacity={1}
            title="Contract - Zonal Retail Systems"
            subtitle="ADMG Solutions had a 6 month Contract with Zonal Retail Data Systems"
            items={[
                {
                    ListItem: "Auth0 Permissions and authentication policies"
                },
                {
                    ListItem: "Built a proxy service to interact with external providers and customise return"
                },
                {
                    ListItem: "Completion of Web API for a webservice "
                },
                {
                    ListItem: "Setup CI jobs on TeamCity server"
                },
                {
                    ListItem: "Technologies used: .NET, C#, Microservices, Rest Services, Azure platform, Azure B2C, Table Storage, CosmosDB"
                }]}/>

        <FeaturesListSection
            bg="white"
            textColor="dark"
            size="md"
            bgImage=""
            bgImageOpacity={1}
            title="Contract - Deloitte Digital"
            subtitle="With Deloitte Digital ADMG Solutions worked on a short engagement with a government client which involved building some support services for future use."
            items={[
                {
                    ListItem: "Azure based roles authentication service with B2C orchestration"
                },
                {
                    ListItem: "Built a proxy service to interact with external providers and customise return"
                },
                {
                    ListItem: "Completion of several Web API components"
                },
                {
                    ListItem: "Setup Azure build pipelines with yaml files to deploy Kubernetes clusters"
                },
                {
                    ListItem: "Technologies used: .NET, C#, Microservices, Rest Services, Azure platform, Auth0, Table Storage, CosmosDB, Gremlin, Kubernetes, Helm charts"
                }]}/>
      <TeamBiosSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Meet the Team"
        subtitle=""
      />
    </>
  );
}

export default AboutPage;
