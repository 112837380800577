import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "./SectionHeader";
import TeamBios from "./TeamBios";

function TeamBiosSection(props) {
  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center"
        ></SectionHeader>
        <TeamBios
          items={[
            {
              avatar: "me.jpg",
              name: "Alasdair Green",
              role: "Software Engineer/Director",
              bio:
                "Alasdair has over 15 years of programming experience, he's been coding since he was a teenager in his bedroom. Built Trading systems, Identify management, computer vision systems, you name it, he's done it.",
              twitterUrl: "https://twitter.com/allydmg",
              linkedinUrl: "https://www.linkedin.com/in/alasdairgreen/"
            }
          ]}
        ></TeamBios>
      </Container>
    </Section>
  );
}

export default TeamBiosSection;
