import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SectionHeader from "./SectionHeader";
import Image from "react-bootstrap/Image";
import "./Features.scss";
import ListGroup from "react-bootstrap/ListGroup";

function FeaturesList(props) {
  return (
    <div className="Features">
        <Row className="align-items-center">
        <Col>
            {props.items.map((item, index) => (
              <ListGroup variant="flush">
                <ListGroup.Item>{item.ListItem}</ListGroup.Item>
              </ListGroup>
              ))}
          </Col>
          <Col>
            <figure className="Features__image-container">
              <Image src={props.image} alt={props.title} fluid={true}></Image>
            </figure>
          </Col>
        </Row>
    </div>
  );
}

export default FeaturesList;
