import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "./SectionHeader";
import Clients from "./Clients";

function ClientsSection(props) {
  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className="text-center">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
        />
        <Clients
          items={[
            {
              name: "React",
              image: "1024px-React-icon.svg.png",
              width: "150px",
            },
            {
              name: "Swift",
              image: "Swift_logo_vert_lockup_color_rgb.svg",
              width: "135px"
            },
            {
              name: "dotnet",
              image: "NET_Core_Logo.svg",
              width: "135px"
            },
              {
                  name: "python",
                  image: "python-logo-generic.svg",
                  width: "270px"
              }
          ]}
        />
      </Container>
    </Section>
  );
}

export default ClientsSection;
