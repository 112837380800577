import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "./SectionHeader";
import Faq from "./Faq";

function FaqSection(props) {
  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center"
        ></SectionHeader>
        <Faq
          items={[
            {
              question: "Can you integrate our platform with our other third party providers?",
              answer:
                "Yes! Systems integration is one of our specialities at ADMG Solutions, we've worked on anything from big integrations between enterprise level products and simple integrations between mobile applications and web api's."
            },
            {
              question: "Can you build a mobile application for me?",
              answer:
                "Yes! We have experience building natively in Swift on iOS and react-native for cross platform"
            },
            {
              question: "What if I need an API for my platform?",
              answer:
                "We can build that API for you to allow your mobile app to connect to your platform. I'll work with you to identify your needs and what data needs exposed/"
            },
            {
              question: "What about security?",
              answer:
                "Not a problem, I have experience creating secure applications where we can make your data encrypted in transit."
            },
            {
              question: "Can you build for the Cloud?",
              answer:
                "Yes! We are experts at building with Microsoft Azure and some small providers such as Linode."
            },
              {
                  question: "When can we start building?",
                  answer:
                      "Get in touch and we can get the ball rolling."
              }
          ]}
        ></Faq>
      </Container>
    </Section>
  );
}

export default FaqSection;
