import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "./SectionHeader";
import Features from "./Features";

function FeaturesSection(props) {
  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center"
        ></SectionHeader>
        <Features
          items={[
            {
              title: ".Net",
              description:
                "Our area of expertise is using the Microsoft stack, working with .NET Core on the Microsoft Azure platform.",
              image: "blue-and-yellow-graph-on-stock-market-monitor-159888.jpg"
            },
            {
              title: "CaseNotes iOS",
              description:
                "Native swift with restful services. Want casenotes on the go? Of course you do. The mobile app syncs up to your casenotes platform. No mobile connection? No problem - it'll store offline untill you can sync at a time that suits you. ",
              image:
                "white-iphone-xr.jpg"
            },
            {
              title: "Casenotes Web",
              description:
                "CaseNotes built with python. CaseNotes is a whitelabel system designed to allow you to take all the notes you need about clients. Reporting and billing is built in. Bespoke features can be built to suit your needs. ",
              image: "coding-computer-data-depth-of-field-577585.jpg"
            },
            {
              title: "Bespoke Development",
              description:
                "Together we will take you through from your discovery of needs, analyse what you need to build. Then we'll architect the solution to be perfect to your needs. Talk about rigor, tests, deployment pipelines, move to the cloud.",
              image: "schedule-planning-startup-launching-7376.jpg"
            }
          ]}
        ></Features>
      </Container>
    </Section>
  );
}

export default FeaturesSection;
